<template>
    <div  class="validationDiv">
        <header>
            <div class="navArea" >
                <smallbutton toplabel="Mobile IDs" :buttonclass="navButtonClass('mobileid')" @click="selectNav('mobileid')" />
                <smallbutton toplabel="Mobile ID Setup" :buttonclass="navButtonClass('management')" @click="selectNav('management')"/>
                <smallbutton toplabel="Messaging" :buttonclass="navButtonClass('message')" @click="selectNav('message')"/>
                <smallbutton toplabel="Reports" :buttonclass="navButtonClass('reports')" @click="selectNav('reports')" v-if="showReports"/>
                <smallbutton toplabel="Validate Images"  @click="gotovalidate()" v-if="showValidation" buttonclass="unselectedNav" />
            </div>      
        </header>

        <!-- MAIN -->
        <div class="mainDiv" v-if="currentNav=='mobileid'">
            <div class="horizFlexStretch fullWidth">
                <div class="horizFlex">
                   
                    <button :class="buttonActionNarrowClass(1)" @click="setzoom(1)">
                        <img class="colimg" src="@/assets/one column.svg"/>
                    </button>
                    <button :class="buttonActionNarrowClass(2)" @click="setzoom(2)">
                        <img class="colimg" src="@/assets/two columns.svg"/>
                    </button>
                    <button class="buttonActionNarrow marginleft" @click="zoomOut()">{{zoomOutLabel}}</button>
                    <button class="buttonActionNarrow" @click="zoomIn()">{{zoomInLabel}}</button>
                    
                </div>
                
                <!-- filters -->
                <div>
                    <div class=" filterdiv">
                         <button class="filterbutton left5"  @click="resetFilter()">Reset</button>
                        <button class="filterbutton"  @click="getBatch()">Go</button>

                         <label class="filterlabel" for="">Include Inactive</label>
                            <input class="filtercheck" type="checkbox"  v-model="filter.includeInactive" /> 
                        <input class="filtertext" placeholder="lookup value" v-model="filter.lookupValue" @keyup.enter="getBatch()"/>
                        
                        <select name="" id="" class='filterselect' v-model="filter.personRoleId" >
                            <option v-for="role in personRoleList" :key="role.id" :value="role.id" >
                                {{role.name}}
                            </option>
                        </select>

                        <select name="" id="" class='filterselect' v-model="filter.departmentId" >
                            <option v-for="department in departments" :key="department.id" :value="department.id" >
                                {{department.name}}
                            </option>
                        </select>
                        
                        <select name="" id="" class='filterselect' v-model="filter.locationId">
                            <option v-for="location in locations" :key="location.id" :value="location.id" >
                                {{location.name}}
                            </option>
                        </select>

                        <label class="filtertitle" for="">Filter by </label>
                        
                    </div>
                    <div class="filterdiv">
                       <input class="filtertext" :placeholder="startDate2Text" type="date" v-if="showDate2" v-model="filter.initialHoursDate"  @keyup.enter="getBatch()"/>
                            <label class="filterlabel2" for="" v-if="showDate2" >{{startDate2Text}}</label>
                        <input class="filtertext" :placeholder="startDate1Text" type="date" v-model="filter.hireDate"  @keyup.enter="getBatch()"/>
                            <label class="filterlabel2" for="">{{startDate1Text}}</label>
                    </div>
                    <div class=" filterdiv">
                            <label class="filterlabel3" for="" v-if="showDate2" >Only Unassigned {{startDate2Text}}</label>
                            <input class="filtercheck2" type="checkbox" v-if="showDate2"  v-model="filter.nullInitialHoursDate" @change="checkStartDate()"/>
                            <label class="filterlabel3" for="" >Only Unassigned {{startDate1Text}}</label>
                            <input class="filtercheck2" type="checkbox"  v-model="filter.nullHireDate" @change="checkStartDate()"/>
                              
                    </div>
                </div>
            </div>
             <label v-if="pleaseWait" class="pleasewaitbig">Please Wait while we get your badges...</label>
            
            <!-- record -->
            <div :class="scrollBoxDiv">
                <div v-bind:class="recordClass(record)" v-for="record in StudentRecords" :key="record.id">
                    <div :class="persongridclass">
                        <div class="vertFlexLeft">
                            <p class="recordText bold">{{record.name}}</p>
                            <p class="recordText bold">{{record.badgeNumber}}</p>
                            <p class="recordTextsmall">{{record.badgeType}}</p>
                            
                            <p class="recordTextsmall">Status: {{record.badgeStatus}}</p>

                            <p class="recordTextsmall">Details: {{record.badgeDetails}}</p>
                            <p class="recordTextsmall">Amount Due: {{record.badgeAmountDue | toCurrency}}</p>
                            <p class="recordText">{{startDate1Text}}: {{record.startDate | formatDate}}</p>
                            <p class="recordText" v-if="showDate2" >{{startDate2Text}}: {{record.startDate2 | formatDate}}</p>
                            <p class="recordText bold">Score: {{record.combinedScore}}</p>
                            <p class="recordText" v-if="showARStatus">Is AR: {{record.accountsReceivable}}</p>
                        </div>
                        <div class="vertFlexLeft" v-if="showContactInfo">
                            <p class="recordTextsmall">{{record.phoneNumber | formatPhone}}</p>
                            <p class="recordTextsmall">{{record.emailAddress}}</p>
                            <p class="recordTextsmall">{{record.city}}</p>
                        </div>
                    </div>
                    
                    <div class="horizFlex">
                        <div class="horizFlex" v-for="image in record.imageList" :key="image.caption">
                            <div class="vertFlex imageAndCaption">
                                <img  :src="getImage(image)" alt="" :class="recordImageClass(image)" @click="toggleImageSize(image,$event.currentTarget,record)">
                                <p class="imageCaption">
                                    {{image.badgeImageTypeName}}
                                </p>
                                <button v-if="image.enlarged" @click="$refs.imgedit.openModal()">Edit</button>
                                <button v-if="image.enlarged && allowReplaceImage" @click="$refs.imgall.openModal()">Replace</button>
                            </div>
                        </div>  
                    </div>
                    
                    <div class="vertFlex">
                        <div class="rightjustified">
                            <button class="sendbutton" @click="sendToStudent(record.badgeId, record.personId)">
                                <img src="@/assets/email-send-outline.png" class="sendicon"/>
                            </button>
                        </div>
                        <documentadmin :record="record" v-if="showDocuments"/>
                       <updatestartdate :person="record" @updated="startDateUpdated" @updated2="startDate2Updated" />
                       <arstatus :person="record" @updated="arUpdated" v-if="showARStatus"/>
                        <!-- <alertlog :record="record"/> -->
                        <!-- <div >
                            <newbadge @refresh="getBatch" :badge="record" :isUpdate="paramTrue"/>
                        </div> -->
                       
                        <!-- images -->
                        <!-- <div class="horizFlex">
                            <div class="horizFlex" v-for="image in record.imageList" :key="image.caption">
                                <div class="vertFlex imageAndCaption">
                                    <img  :src="getImage(image)" alt="" :class="recordImageClass(image)" @click="toggleImageSize(image,$event.currentTarget,record)">
                                    <p class="imageCaption">
                                        {{image.badgeImageTypeName}}
                                    </p>
                                    <button v-if="image.enlarged" @click="$refs.imgedit.openModal()">Edit</button>
                                </div>
                            </div>  
                        </div> -->
                       
                    </div>
                    
                </div>
            </div>

           
        </div>
        
        <!-- MGMT -->
        <div class="mainDiv" v-if="currentNav=='management'">
            <div class="horizFlex">
                <div class="framed">
                    <newbadgeframe @refresh="getBatch"/>
                </div>
            </div>
            <div class="horizFlex">
                <newbadgedesign @refresh="getBatch"/>
                <badgeinfoadmin  @refresh="getBatch"/>
                <uploadfile @refresh="getBatch" v-if="showImportButton"/>
                <picturerequirements/>
            </div>
        </div>

        <!-- MESSAGE -->
        <div class="mainDiv" v-if="currentNav=='message'">
            <div class="horizFlex">
                <div class="framed">
                    <sendmessageframe ref="sendmessage"/>
                </div>
                <div class="framed">
                    <sendalertframe ref="sendalert"/>
                </div>
                
            </div>
            <div class="horizFlex">
                   
                <messagetemplate  @refresh="getBatch"/>
            
                <badgealerttemplateadmin  @refresh="getBatch"/>

            </div>
        </div>

        <!-- REPORTS -->
        <div class="mainDiv" v-if="currentNav=='reports'">
            <walletreport v-if="showReports"/>
        </div>

        <!-- ACTIONS -->
        <imageeditor ref="imgedit" :img="selectedImageURL" @save="saveedits"/>
        <imageall ref="imgall" :img="selectedImageURL" @saveimg="saveedits" :allowFileSelect="paramTrue" :showImage="paramFalse"/>

        <actionmodal ref="sendModal"> 
                <template v-slot:body>
                    <label >Please select which message to send</label>
                    <div>
                        <select name="" id="" class='filtertemplate' v-model="sendToTemplateId" >
                            <option v-for="template in messageTemplateList" :key="template.id" :value="template.id" >
                                {{template.name}}
                            </option>
                        </select>
                    </div>
                    
                    <div>
                        <smallbutton   @click="finishSendToStudent()" toplabel="Send"/>
                        <smallbutton  @click="$refs.sendModal.closeModal()" toplabel="Close"/>
                    </div>
                    <div>
                        <label v-if="pleaseWait" class="pleasewait">Please Wait</label>
                        <label>{{message}}</label>
                    </div>
                </template>
            </actionmodal>


            

   
        
    </div>

</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodalwide from '../controls/actionmodalwide.vue'
import actionmodal from '../controls/actionmodal.vue'
import {mapFields} from 'vuex-map-fields'
import style from '../../style.js'
import imageeditor from '../controls/ImageEditor.vue'
import imageall from '../controls/ImageAll.vue'
import sendemail from '../actions/sendemail.vue'
import formentry from '../controls/entry.vue'
import format from '../../format.js'
import newbadgeframe from '../actions/newbadgeframe.vue'
import newbadgedesign from '../actions/newBadgeDesign.vue'
import messagetemplate from '../actions/messageTemplate.vue'
import uploadfile from '../actions/uploadfile.vue'
import badgeinfoadmin from '../actions/badgeInfoAdmin.vue'
import badgealerttemplateadmin from '../actions/AlertTemplateAdmin.vue'
import walletreport from '../report/report.vue'
import sendalertframe from '../actions/sendalertframe.vue'
import sendmessageframe from '../actions/sendmessageframe.vue'
import alertlog from '../actions/alertlog.vue'
import documentadmin from '../actions/documentAdmin.vue'
import picturerequirements from '../actions/picturerequirements.vue'
import updatestartdate from '../actions/startDate.vue'
import arstatus from '../actions/arstatus.vue'

export default {
    name:'badgeadmin',
    components:{
        smallbutton,
        linkbutton,
        actionmodalwide,
        imageeditor,
        imageall,
        sendemail,
        actionmodal,
        formentry,
        sendmessageframe,
        newbadgeframe,
        newbadgedesign,
        messagetemplate,
        uploadfile,
        badgeinfoadmin,
        badgealerttemplateadmin,
        walletreport,
        sendalertframe,
        alertlog,
        picturerequirements,
        documentadmin,
        updatestartdate,
        arstatus
    },
    props:{ 
    },
   
    mixins:[datamethods,style,format],
    data(){
        return{
            currentNav:'mobileid',
            timeoutInMiliseconds:window.TIMEOUT_MILISECONDS,

            imageBasePath:'',
            logoFilePath:'./../' + window.SCHOOL_LOGO,
            

            inValidation:false,

            zoom:2,
            zoomImageSize:60,
            zoomOutLabel:"-",
            zoomInLabel:"+",
            spaceText:"  ",
            
            StudentRecords:[],
            batchName:'',

            batchDateTime:null,

            selectedImageURL:null,
            selectedImage:null,
            selectedImageTypeID:null,
            selectedImageBadgeID:null,

            selectedRecord:null,


            //filters

            filter:{
                locationId:-1,
                departmentId:-1,
                personRoleId:-1,
                lookupValue:'',
                includeInactive:false,
                includeExpired:false,
                includeNotYetEffective:false,
                hireDate:null,
                initialHoursDate:null,
                nullHireDate:false,
                nullInitialHoursDate:false,
            },

            //send to
            sendToBadgeId:-1,
            sendToTemplateId:-1,
            sendToPersonId:-1,

            message:'',
            pleaseWait:false,

            //alert template
            sendToAlertTemplateId:-1,

            scoremessage:'',
            
            //for giving to component params
            paramTrue:true,
            paramFalse:false,

        }
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'logoOverride',
            'locations',
            'personRoleList',
            'departments',
            'alertTemplateList',
            'messageTemplateList',
            'token'

        ]),
        validationURL(){
            return window.URL_VALIDATION 
        },
        scrollBoxDiv(){
            //use the number to get the right class
            return "scrollDiv zoom" + this.zoom
        },
        logoPath(){
            return this.logoFilePath
        },
        showContactInfo(){
            
            if(window.SHOW_BADGE_CONTACTINFO==undefined){
                return false
            }else{return window.SHOW_BADGE_CONTACTINFO}
        },
        showARStatus(){
            if(window.SHOW_AR_STATUS==undefined){
                return false
            }else{return window.SHOW_AR_STATUS}
        },
        getBadgesAtOpen(){
            
            if(window.GET_BADGES_AT_OPEN==undefined){
                return true
            }else{return window.GET_BADGES_AT_OPEN}
        },
        
        startDate1Text(){
            
            if(window.START_DATE1_TEXT==undefined){
                return 'Start Date'
            }else{return window.START_DATE1_TEXT}
        },
        startDate2Text(){
            
            if(window.START_DATE2_TEXT==undefined){
                return 'Start Date 2'
            }else{return window.START_DATE2_TEXT}
        },
        showDate2(){
            
            if(window.SHOW_DATE2==undefined){
                return false
            }else{return window.SHOW_DATE2}
        },
        showContactInfo(){
            
            if(window.SHOW_BADGE_CONTACTINFO==undefined){
                return false
            }else{return window.SHOW_BADGE_CONTACTINFO}
        },
        persongridclass(){
            if(this.showContactInfo){return "persongrid"}
            else{return "VertFlexLeft"}
        },
        showImportButton(){
            if(window.SHOW_IMPORT_BUTTON==undefined){
                return false
            }else{return window.SHOW_IMPORT_BUTTON}
        },
        showValidation(){
            if(window.SHOW_VALIDATION_BUTTON==undefined){
                return true
            }else{return window.SHOW_VALIDATION_BUTTON}
        },
        showReports(){
            if(window.SHOW_REPORTS_BUTTON==undefined){
                return true
            }else{return window.SHOW_REPORTS_BUTTON}
        },
        showDocuments(){
            if(window.SHOW_DOCUMENTS_BUTTON==undefined){
                return true
            }else{return window.SHOW_DOCUMENTS_BUTTON}
        },
        allowReplaceImage(){
            if(window.ALLOW_REPLACE_IMAGE==undefined){return false}
            else{return window.ALLOW_REPLACE_IMAGE}
        }
    },
   mounted(){
       this.start()
   },
    methods:{
        selectNav(name){
            this.currentNav=name;
        },
        navButtonClass(name){
            if(this.currentNav==name){
                return "selectedNav"
            }else{
                return "unselectedNav"
            }
        },
        startDateUpdated(id, newdate){
            console.log('updated ' + id + ' ' + newdate)

             this.StudentRecords.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let a=arr[index]
                    if(a.personId==id){
                        a.startDate=newdate
                    }
                }

        },
        startDate2Updated(id, newdate){
            console.log('updated ' + id + ' ' + newdate)

             this.StudentRecords.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let a=arr[index]
                    if(a.personId==id){
                        a.startDate2=newdate
                    }
                }

        },
        arUpdated(id, isAR){
            this.StudentRecords.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let a=arr[index]
                    if(a.personId==id){
                        a.accountsReceivable=isAR
                    }
                }
        },
        /* handleAuthResponse(){
            if(this.message.includes('You are not authorized')){this.loggedIn=false}
        }, */
        getUrl(url){
            var outurl = url 
            if(this.logoOverride!=''){
                outurl=outurl + "?logo=" + this.logoOverride + '&token=' + this.token;
            }else{
                outurl=outurl + "?token=" + this.token;
            }

            console.log(outurl)
            return outurl
        },
        gotovalidate(){
            window.open(this.getUrl(this.validationURL))
        },
        start(){
            this.inValidation=true
            this.$emit('start')
            this.getImageBasePath()
            this.getLocations()
            this.getDepartments()
            this.getPersonRoleList()
            this.getTemplates()
            this.getAlertTemplates()
        
           if(this.getBadgesAtOpen){ this.getBatch()}
           this.filter.startDate=this.$options.filters.formatDateForInputToday()
        },
        async scoreBatch(){
            this.scoremessage = "Scoring Batch in the background..."
            //call api
            var response = await this.callAPI(null,'scorebatch')
            //handle response
            if(response.Successful){
                this.scoremessage="Scoring Complete"
                this.getBatch()
            }else{
                this.scoremessage = response.Message
            }
            
        },
        
        reports(){
            this.$refs.reportpopup.openModal()
        },
        //send to student
        sendToStudent(id,pid){
            this.sendToBadgeId=id;
            this.sendToPersonId=pid
            this.$refs.sendModal.openModal();
        },
        async finishSendToStudent(){
            let req = {
                badgeId:this.sendToBadgeId,
                templateId:this.sendToTemplateId,
                personId:this.sendToPersonId
            }
            this.message=""
            this.pleaseWait=true;
            var response = await this.callAPI(req,'sendtoperson')
            if(response.Successful){
                this.message = "Message Sent"
                this.getBatch()
                //this.$refs.sendModal.closeModal()
            }else{
                this.message = response.Message
                //this.handleAuthResponse()
            }
            this.pleaseWait=false;
        },
        
        //send alert
        async send(){
            this.$refs.sendalert.Start()
    
        },
        sendmessage(){
            this.$refs.sendmessage.Start()
        },
        //alertlog
        

        //timeout
        timeOut(){
         //this.loggedIn=false;
        },
        resetTimer(miliseconds){
            clearTimeout(this.inactiveTime);
            this.inactiveTime = setTimeout(this.timeOut, miliseconds);
        },
        resetFilter(){
            this.filter={
                locationId:this.loggedInUser.locationId_Primary,
                departmentId:-1,
                personRoleId:-1,
                lookupValue:'',
                includeInactive:false,
                includeExpired:false,
                includeNotYetEffective:false,
                hireDate:null,
                initialHoursDate:null,
                nullHireDate:false,
                nullInitialHoursDate:false
            }
            this.getBatch()
        },
        checkStartDate(){
            var studentId 
            if(this.filter.nullHireDate){
                this.personRoleList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let a=arr[index]
                    if(a.name=='Student'){
                        studentId=a.id
                        console.log('id ' + studentId)
                    }
                }
                this.filter.personRoleId=studentId
                
            }else{this.filter.personRoleId=-1}
            
            this.getBatch()
        },
        async getBatch(){
            this.pleaseWait=true;
            //setup request
            let req = this.filter
            
            //call api
            var response = await this.callAPI(req,'personbadgelist')
            
            //handle response
            if(response.Successful){
                
                this.StudentRecords=response.Batch.validationItems
                this.batchName = response.Batch.batchName
                this.batchDateTime = response.Batch.batchDateTime

              
            }else{
                this.message = response.Message
               // this.handleAuthResponse()
            }
            this.pleaseWait=false;
            this.resetTimer(this.timeoutInMiliseconds);
        },
        
        //filter lists
        async getPersonRoleList(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'rolelist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.personRoleList=response.PersonRoleList
            }else{
                this.message = response.Message
               // this.handleAuthResponse()
            }
        },
        async getDepartments(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'departmentlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.departments=response.DepartmentList
            }else{
                this.message = response.Message
                //this.handleAuthResponse()
            }
        },
        async getLocations(){
            this.message=""
            //call api
            var response = await this.callAPI(null,'locationlist')
            //handle response
            this.tmpresp = response
            if(response.Successful){
                this.locations=response.institutionLocationList

                //set to user's
                this.filter.locationId=this.loggedInUser.locationId_Primary
            }else{
                this.message = response.Message
                //this.handleAuthResponse()
            }
        },
        async getTemplates(){
            var response = await this.callAPI(null,'templates')
            if(response.Successful){

                this.messageTemplateList=response.MessageTemplateList

            }else{
                //this.message = response.Message
               // this.handleAuthResponse()
            }
        },
       
        //record
        recordClass(record){
            var myclass=""
            if(record.outstatus!=null){
                if(record.outstatus=="pass"){
                    myclass= "horizFlex record recordPass"
                }else if(record.outstatus=="fail"){
                     myclass= "horizFlex record recordFail"
                }else{
                     myclass= "horizFlex record recordMaybe"
                }
            }else{
                 myclass= "horizFlex record recordUnselected"
            }
            if(record.instatus=='pass'){myclass=myclass + ' recordPassIn'}
            else if(record.instatus=='maybe'){myclass=myclass + ' recordMaybeIn'}
            else if(record.instatus=='fail'){myclass=myclass + ' recordFailIn'}

    
            return  myclass
            
        },
        //record image
        async getImageBasePath(){
            //call api
            var response = await this.callAPI(null,'imagepath_api')
            //handle response
            if(response.Successful){
                this.imageBasePath = window.VUE_CONFIG_API + response.StringResponse
            }else{
                this.responseMessagemessage = response.Message
            }
        },
        getImage(image){
            return this.imageBasePath + image.fileName
        },
        recordImageClass(image){
            var rclass = ""
            if(image.enlarged){rclass= "recordImageEnlarged"}
            else{rclass= "recordImage"}

            //add color border
            if(image.instatus!=null){
                if(image.instatus=="pass"){
                    rclass=rclass + " passborder"
                }else if(image.instatus=="fail"){
                    rclass=rclass + " failborder"
                }else{
                    rclass=rclass + " maybeborder"
                }
            }
            return rclass

        },
        toggleImageSize(image,img,item){
            console.log('el ' + img)
            image.enlarged = !image.enlarged
            if(image.enlarged){
                this.selectImage(image,img,item)
            }
        },
        selectImage(image,img,item){
            this.selectedRecord=item
            this.selectedImage=image
            this.selectedImageTypeID=image.badgeImageTypeId
            this.selectedImageURL= img.src
            this.selectedImageBadgeID = item.badgeId
        },
        saveedits(file, url){
            console.log('saveedits')
            this.selectedImageURL=url
        
            this.storeImage("", file, file.name)
        },
       async storeImage(filename,file, name){
            var bId=this.selectedImageBadgeID
            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('badgeId',bId);
            postParams.append('typeid',this.selectedImageTypeID);

            var response = await this.callAPI(postParams,'saveimage')
            //handle response
            if(response.Successful){
                this.getBatch()
            }else{
                this.responseMessagemessage = response.Message
            }
        },

        //zoom
        buttonActionNarrowClass(col){
            if(col==this.zoom){
                return "buttonActionNarrow bordered"
            }else{return "buttonActionNarrow"}
        },
        setzoom(col){
            this.zoom = col
            
        },
        zoomIn(){
            //if(this.zoom <6){this.zoom = this.zoom + 1}
            if(this.zoomImageSize<120){this.zoomImageSize+=20}
           
            document.documentElement.style.setProperty('--image-size',this.zoomImageSize + 'px');
        },
        zoomOut(){
            if(this.zoomImageSize >20){this.zoomImageSize = this.zoomImageSize - 20}
           
            document.documentElement.style.setProperty('--image-size',this.zoomImageSize + 'px');
        },


        //alert and msg
        async getAlertTemplates(){
            var response = await this.callAPI(null,'badgealerttemplatelist')
            if(response.Successful){

                this.alertTemplateList=response.BadgeAlertTemplateList
                this.pleaseWait=false
            }else{
                //this.message = response.Message
            }
        },
        
    }

}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";
:root{
    /* --text-color:rgb(58, 54, 54)!important;
    --light-text-color:white!important;
    --button-color:#075190!important; */
    --pass-color:rgb(120, 195, 120)!important;
    --fail-color:rgb(221, 161, 161)!important;
    --maybe-color:rgb(236, 236, 142)  ;
    --unselected-color:lightgray!important;
    --pass-out-color:rgb(97, 159, 230)!important;
    --fail-out-color:gray!important;
    --image-size:60px;
}
.validationDiv{
     height:100vh;
    display:grid;
    grid-template-columns:1fr 4fr;
    grid-template-rows:90px auto;
    grid-template-areas: 
        "header header header"
        "center center center";
    font-size:x-large;
}
.filterVar{
    display:flex;
    padding:0px;
    flex-direction: row;
    justify-content:space-between;
    width:97%;

}
.persongrid{
    display:grid;
    grid-template-columns:1fr 1fr;
    text-align:left;
    width:400px;
}
.mainDiv{
    grid-area: center;
    background-color:var(--background-color);
    /* background-image:linear-gradient(white,gray); */
    padding:5px;
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height:87vh;
}
header{
    grid-area:header;
    padding-bottom:10px;
    background-color:var(--header-color);
}
.footer{
    grid-area:footer;
}

.lookup{
  height:40px;
  margin:5px;
  font-size:inherit;
}
/* filter */
.filterlabel{
    margin-top:10px;
}
.filterlabel2{
   font-size:medium;
    min-width:80px;
    margin:5px 10px 0px 10px;
    text-align:left;
    color:white;
}
.filterlabel3{
   font-size:medium;
    min-width:80px;
    margin:5px 30px 0px 5px;
    text-align:left;
    color:white;
}
.buttonFilterWide{
  padding:5px;
   border-radius:5px;
   border:solid;
   border-width: 1px;
   font-size:large;
    width:120px;
    height:50px;
    margin:5px;
}

.buttonFilter{
  padding:5px;
   border-radius:5px;
   border:solid;
   border-width: 1px;
   font-size:large;
    width:80px;
    height:50px;
    margin:5px;
}
.buttonFilterSelected{
  padding:5px;
   border-radius:5px;
   border:solid;
   border-width: 3px;
   font-size:large;
    width:80px;
    height:50px;
    margin:5px;
    font-weight:bold;
    border-color:var(--button-color);
}
.buttonImage{
  height:40px;
}
.actionbuttontext{
  font-size:medium;
  margin-top:0px;
  margin-left:5px;
}
/* scrolldiv */
.scrollDiv{
    height:100%;
    width:97%;
    padding:10px;
    overflow-x:auto;
    overflow-y:scroll;
    background-color:var(--background-color);
    display:grid;
}
.zoom1{
    grid-template-columns:1fr;
}
.zoom2{
    grid-template-columns:1fr 1fr;
}
.zoom3{
    grid-template-columns:1fr 1fr 1fr;
}
.zoom4{
    grid-template-columns:1fr 1fr 1fr 1fr;
}
.zoom5{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}
.zoom6{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}

/* record */
.record{
  padding:10px;
  border-style: none;
  border-radius:5px;
  justify-content:space-between;
  align-items: center;
  margin-bottom:10px;
  margin-right:10px;
}
.recordPassIn{
  border-left:10px solid var(--pass-color);
}
.recordMaybeIn{
    border-left:10px solid var(--maybe-color);
}
.recordFailIn{
    border-left:10px solid var(--fail-color);

}
.recordPass{
  background-color:var(--pass-color);
}
.recordMaybe{
  background-color:var(--maybe-color);
}
.recordUnselected{
  background-color:var(--frame-color);
}
.recordFail{
  background-color:var(--fail-color);
}
.recordPass_Out{
  background-color:var(--pass-out-color);
}
.recordFail_Out{
  background-color:var(--fail-out-color);
}

.recordneutral{
  background-color: rgb(235, 235, 146) ;
}
.recordText{
    font-size:medium;
  margin:0px;
}
.recordTextsmall{
  font-size:medium;
  margin:0px;
}
/* record image */
.imageAndCaption{
  border-radius:5px;
  /* border:var(--border-color);
  border-width: 1px;
  border-style: solid; */
  padding:5px;
  background-color:white;
}
.imageCaption{
  font-size:small;
  margin:0px;
}
.recordImage{
  height:var(--image-size);
  transition: height 1s;
}
.recordImageEnlarged{
  height:150px;
}
.recordImage:hover{
  height:150px;
}
.passborder{
    border:3px solid var(--pass-color);
}
.maybeborder{
    border:3px solid var(--maybe-color);
}
.failborder{
    border:3px solid var(--fail-color);
}

/* bar */
.bar{
  height:30px;
  width:168px;
  margin:0px 0px 10px 0px;
}
.redbar{
  background-color: var(--fail-color) ;
}
.greenbar{
  background-color: var(--pass-color) ;
}
.yellowbar{
  background-color: rgb(235, 235, 146) ;
}

/* actions */

.buttonAction{
    padding:5px;
    border-radius:20px;
    border:none;
    font-size:inherit;
    min-width:100px;
    height:50px;
    margin:5px;
}
.buttonActionNarrow{
    padding:5px;
    border-radius:20px;
    border:none;
    font-size:inherit;
    width:40px;
    height:50px;
    margin:5px;
}
.bordered{
    border:1px solid black;
}
.framed{
    background-color:var(--frame-color);
    border-radius: 5px;
    margin:10px;
    padding:5px 10px 5px 10px;
}
.primary{
    background-color:var(--button-color);
    color:var(--light-text-color);
}
/* totals */
.totallabel{
  margin-right:10px;
  margin-top:10px;
  color:white;
  font-weight:bold;
}

/* confirmation */
.confirmbutton{
  padding:5px;
   border-radius:20px;
   border:none;
   font-size:inherit;
    width:110px;
    height:50px;
    margin:5px;
    background-color:var(--button-color);
    color:white;
}
.confirmbuttonwide{
  padding:5px;
   border-radius:20px;
   border:none;
   font-size:inherit;
    width:300px;
    height:50px;
    margin:5px;
    background-color:var(--button-color);
    color:white;
}


.lighttext{
    color:var(--light-text-color);
}
.marginright{
    margin-right:10px;
}
.marginleft{
    margin-left:30px;
}
.mediumtext{
    font-size: medium;
}
.colimg{
    height:20px;
}
.filterselect{
    font-size:medium;
    height:30px;
    width:150px;
    margin-right:10px;
}
.filtertitle{
    font-size:large;
    min-width:80px;
    margin:5px 0px 0px 0px;
    text-align:left;
    color:white;
}
.filterlabel{
    font-size:medium;
    min-width:150px;
    margin:5px 0px 0px 5px;
    text-align:left;
    color:white;
}
.filtercheck{
    margin:0px 0px 2px 10px;
}
.filtercheck2{
    margin:2px 0px 0px 0px;
}
.filterdiv{
    width:95%;
    padding:5px;
    display:flex;
    flex-direction: row-reverse;
}
.filtertext{
    font-size:medium;
    height:24px;
    width:150px;
    margin-right:5px;
}
.filtertext2{
    font-size:medium;
    height:24px;
    width:100px;
    margin-right:5px;
}
.filterbutton{
    background-color:var(--button-color);
    border:none;
    color:white;
    border-radius:5px;
}
.filtertemplate{
    font-size:medium;
    height:30px;
    width:300px;

}
.title{
    color:white;
    margin:10px;
}
.sendbutton{
    border:none;
    background-color:transparent;
}
.scorelabel{
    color:white;
    font-size:small;
    margin-top:10px;
}
.scorebutton{
    margin-top:15px;
    font-size:large;
}
.pleasewaitbig{
    color:orangered;
    background-color:white;
    font-size:x-large;
    padding:5px;
}
.headerbutton{
    background-color:var(--header-button-color);
    color:var(--header-button-text-color);
    border: none;
    border-radius:2px;
    width:130px;
    height:50px;
    padding:2px;
    font-size: medium;
    margin:10px;
  text-decoration: none;
}
.selectedNav{
    color:var(--light-text-color);
    background-color:var(--header-button-color);
    border: 4px solid var(--highlight-button-color);
    border-radius:2px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
     margin:10px;

}
.unselectedNav{
    color:var(--light-text-color);
    background-color:var(--header-button-color);
    border: none;
    border-radius:2px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
}
</style>


  <!-- <div class="horizFlexStretch">
                <div class="horizFlex">
                    <newbadgedesign @refresh="getBatch"/>
                    <messagetemplate  @refresh="getBatch"/>
                    <badgeinfoadmin  @refresh="getBatch"/>
                    <badgealerttemplateadmin  @refresh="getBatch"/>
                </div>
                
                <div class="horizFlex">
                    <smallbutton toplabel="Validate Images" @click="gotovalidate()" v-if="showValidation" buttonclass="headerbutton"/>
                   
                </div>

                <div class="horizFlex">
                    <smallbutton  @click="send()" toplabel="Send Alert"  buttonclass="headerbutton"/>
                    <smallbutton  @click="sendmessage()" toplabel="Send Message"  buttonclass="headerbutton"/>
                    <smallbutton  @click="reports()" toplabel="Reports" v-if="showReports"  buttonclass="headerbutton"/>
                </div>

            </div> -->

             <!-- summary and zoom -->
            <!-- <div class="filterVar">
                

                <div class="horizFlex ">
                    
                </div>
                
            </div> -->

            <!--  <actionmodal ref="sendalertModal"> 
                <template v-slot:body>
                    <label >Please select which alert to send</label>
                    <div>
                        <select name="" id="" class='filtertemplate' v-model="sendToAlertTemplateId" >
                            <option v-for="template in alerttemplateList" :key="template.id" :value="template.id" >
                                {{template.name}}
                            </option>
                        </select>
                    </div>
                    
                    <div>
                        <smallbutton   @click="finishSendAlert()" toplabel="Send"/>
                        <smallbutton  @click="$refs.sendalertModal.closeModal()" toplabel="Close"/>
                    </div>
                    <div>
                        <label v-if="pleaseWait" class="pleasewait">Please Wait</label>
                        <label>{{message}}</label>
                    </div>
                </template>
            </actionmodal> -->